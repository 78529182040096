var SignupForm = function () {
	this.questionCount = 0;
	this.picoConfig = (jQuery.support.opacity) ? {
		overlayStyles: {
			zIndex: 10003,
			backgroundColor: "#000",
			opacity: 0.75,
			font: "italic bold Trebuchet"
		},
		modalStyles: {
			zIndex: 10004,
			left: '0',
			right: '0',
			top: '50px',
			width: '386px',
			maxWidth: '100%',
			margin: 'auto',
			backgroundColor: '#590f26',
			padding: '20px',
			color: '#fff',
			borderRadius: '5px',
			border: '1px solid #970125',
			textAlign: 'left',
			boxSizing: 'border-box'
		},
		overlayClose: true
	} : {
		overlayClose: true
	};

	this.init = function () {
		var questions = jQuery('.question'),
			formContainer = jQuery('#register'),
			animation = jQuery('#animation'),
			i, j, nodes, handler;

		this.questionCount = questions.length;
		if (formContainer !== null) {
			formContainer.on('submit', jQuery.proxy(function (e) {
				e.preventDefault();
				return this.signUp(document.getElementById('username'), document.getElementById('email'));
			}, this));
		}

		handler = function (e) {
			var nextStep, id, num;
			id = e.target.parentNode.id;
			num = parseInt(e.target.parentNode.id.substring(id.indexOf('_') + 1, id.length));
			num++;
			jQuery.event.trigger({
				type: 'nextStep',
				detail: {
					hide: e.target.parentNode,
					nextStep: num
				}
			});
		};

		for (i = 0; i < questions.length; i++) {
			nodes = questions[i].childNodes;
			for (j = 0; j < nodes.length; j++) {
				if ((nodes[j].tagName !== undefined && nodes[j].tagName.toLowerCase() === 'input') && (nodes[j].type !== undefined && nodes[j].type.toLowerCase() !== 'text')) {
					jQuery(nodes[j]).on('click', handler);
				} else if (nodes[j].tagName !== undefined && nodes[j].tagName.toLowerCase() === 'select') {
					jQuery(nodes[j]).on('change', handler);
				}
			}
		}
		jQuery(document).on('nextStep', jQuery.proxy(this.nextStep));
		jQuery(document).on('highlight', jQuery.proxy(this.highlightStep));
	};

	this.signUp = function (userName, email) {
		var url = 'https://www.lustagenten.com/interface/checkUser2/',
			name = userName.value,
			errorMsg = '',
			checkEmail,
			atPos,
			dotPos,
			reg,
			su,
			s;

		var tr;
		if (name === null || name === "" || name.length < 6) {
			errorMsg = "<li>" + ((tr = translate('enterName')) ? tr : "Bitte gib deinen Namen an (mind. 6 Zeichen, keine Sonderzeichen).") + "</li>";
		}

		//regEX match
		if (/[^a-zA-Z0-9]/.test(name)) {
			errorMsg += "<li>" + ((tr = translate('noSpecialCharacters')) ? tr : "Es sind keine Sonderzeichen im Usernamen erlaubt!") + "</li>";
		}

		checkEmail = email.value;
		atPos = checkEmail.indexOf("@");
		dotPos = checkEmail.lastIndexOf(".");
		if (atPos < 1 || dotPos < atPos + 2 || dotPos + 2 >= checkEmail.length) {
			errorMsg += "<li>" + ((tr = translate('enterEmail')) ? tr : "Bitte gib deine E-Mail an.") + "</li>";
		} else {
			//regEX match
			reg = checkEmail.match(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i);
			if (!reg) {
				errorMsg += "<li>" + ((tr = translate('invalidEmail')) ? tr : "Ungültige E-Mail!") + "</li>";
			}
		}

		if (errorMsg.length > 0) {
			this.picoConfig.content = "<ul>" + errorMsg + "</ul>";
			picoModal(this.picoConfig).afterClose(function (modal) {
				modal.destroy();
				closeButton: false;
			}).show();
		} else {
			su = jQuery('<script>', {
				type: 'text/javascript',
				async: false,
				src: url + '?user_name=' + encodeURIComponent(userName.value) +
				'&user_email=' + encodeURIComponent(email.value)
			});
			s = jQuery('head script').first().parent();
			su.insertBefore(s);
		}
		return false;
	};

	this.signUpResult = jQuery.proxy(function (result) {
		var msg = '';
		if (result === 0) {
			if (typeof disableUnloadMessage === 'function') {
				disableUnloadMessage();
			}
			document.getElementById('register').submit();
		} else {
			var tr;
			msg = [];
			if (result & 0x01) {
				msg.push((tr = translate('invalidUsername')) ? tr : 'Ungültiger Username.');
			} else if (result & 0x02) {
				msg.push((tr = translate('usernameExists')) ? tr : 'Username ist bereits vergeben.');
			}

			if (result & 0x10) {
				msg.push((tr = translate('invalidEmailAddress')) ? tr : 'Ungültige E-Mail-Adresse.');
			} else if (result & 0x20) {
				msg.push((tr = translate('emailIncorrectOrBlocked')) ? tr : 'E-Mail-Adresse fehlerhaft oder blockiert.');
			} else if (result & 0x40) {
				msg.push((tr = translate('checkForTypingErrors')) ? tr : 'Bitte überprüfe deine E-Mail-Adresse auf Tippfehler.');
			} else if (result & 0x80) {
				msg.push((tr = translate('emailAlreadyUsed')) ? tr : 'E-Mail-Adresse ist bereits vergeben.');
			}
			msg = '<li>' + msg.join('</li><li>') + '</li>';
		}

		this.picoConfig.content = "<ul>" + msg + "</ul>";
		if (msg.length > 0) {
			picoModal(this.picoConfig).afterClose(function (modal) {
				modal.destroy();
			}).show();
		}
	}, this);

	this.highlightStep = function () {
		var questions = jQuery('.question'),
			highlightSignup = true,
			form,
			i;

		for (i = 0; i < questions.length; i++) {
			if (questions[i].className.indexOf('hideMe') === -1) {
				highlightSignup = false;
				questions[i].style.opacity = 0.3;
				questions[i].style.borderColor = "#ff1bcd";
				questions[i].style.borderStyle = "solid";
				questions[i].style.borderSize = '1px';

				morpheus(questions[i], {
					opacity: 1,
					borderSize: '3px',
					duration: 800
				});
			}
		}
		if (highlightSignup) {
			form = document.getElementsByClassName('signup')[0];
			form.style.opacity = 1;
			form.style.borderColor = "#ff1bcd";
			form.style.borderStyle = "solid";
			form.style.borderSize = '3px';
		}

	};

	this.nextStep = jQuery.proxy(function (e) {
		var animation = jQuery('#animation');
		if (e.detail.direct !== undefined) {
			var questions = jQuery('.question');
			if (animation.length > 0) {
				jQuery('#questionHead').addClass('hideMe');
			} else {
				jQuery('#registerHeadline').addClass('hideMe');
			}
			questions.addClass('hideMe');
			jQuery('.signup').removeClass('hideMe').css({paddingTop: '20px', opacity: 1});

		} else {
			jQuery(e.detail.hide).addClass('hideMe');
			if (e.detail.nextStep <= this.questionCount) {
				jQuery('#step_' + e.detail.nextStep).removeClass('hideMe');
			} else if (animation.length > 0) {
				animation.removeClass('hideMe');
				this.animate();
			} else {
				jQuery('.signup').removeClass('hideMe');
			}
		}
	}, this);

	this.animate = function () {

		var $bar = jQuery('#progressBar'),
			$describe = jQuery('#serviceDescription'),
			$questionHead = jQuery('#questionHead'),
			$headChecking = jQuery('#checkingHead'),
			$answersHead = jQuery('#resultsHead'),
			$headRules = jQuery('#rulesHead'),
			$finalSignup = jQuery('#finalSignup'),
			$body = jQuery('html, body'),
			animationPos = 0,
			animationList = [
				{
					el: [$describe, $questionHead],
					prop: {
						opacity: 0
					},
					duration: 900
				},
				{
					el: [$bar, $headChecking],
					prop: {
						opacity: 1
					},
					duration: 1000
				},
				{
					el: [$bar],
					prop: {
						width: '220px'
					},
					//easing: easings.easeOutStrong,
					duration: 2500
				},
				{
					el: [$bar, $headChecking],
					prop: {opacity: 0},
					duration: 1000
				},
				{
					el: [jQuery('#results li:nth-child(1)'), $answersHead],
					prop: {opacity: 1},
					duration: 1500
				},
				{
					el: [jQuery('#results li:nth-child(2)')],
					prop: {
						opacity: 1
					},
					duration: 1500
				},
				{
					el: [jQuery('#results li:nth-child(3)')],
					prop: {
						opacity: 1
					},
					duration: 1500
				},
				{
					el: [$headRules],
					prop: {
						opacity: 1
					},
					duration: 1500
				},
				{
					el: [jQuery('#rules li:nth-child(1)')],
					prop: {
						opacity: 1
					},
					duration: 1500
				},
				{
					el: [jQuery('#rules li:nth-child(2)')],
					prop: {
						opacity: 1
					},
					duration: 1500
				},
				{
					el: [jQuery('#rules li:nth-child(3)')],
					prop: {
						opacity: 1
					},
					duration: 1500
				},
				{
					el: [$finalSignup],
					prop: {
						opacity: 1
					},
					duration: 1000
				},
				{
					el: [$body],
					prop: {
						scrollTo: $answersHead
					},
					duration: 2000
				}
			];

		$('.question').fadeOut();

		function doNextAnimation() {
			// Hide old stuff
			if (animationPos < animationList.length) {
				var theAnimation = animationList[animationPos];
				jQuery.each(theAnimation.el, function () {

					if (theAnimation.prop.hasOwnProperty('opacity')) {

						if (theAnimation.prop.opacity == 1) {
							this.fadeTo(theAnimation.duration, theAnimation.prop.opacity, doNextAnimation);
						} else {
							this.fadeOut(theAnimation.duration, doNextAnimation);
						}
					} else if (typeof theAnimation.prop.scrollTo !== 'undefined') {
						this.animate({ 'scrollTop': theAnimation.prop.scrollTo.offset().top }, theAnimation.duration, doNextAnimation); // the offset changes during the animations
					} else {
						this.animate(theAnimation.prop, theAnimation.duration, doNextAnimation);
					}
				});
			}

			animationPos++;
		}

		doNextAnimation();
		document.getElementById('random').innerHTML = Math.round((Math.random() * (10000 - 1000)) + 1000).toString();

	};

};

var initialize = function () {
	var page = new SignupForm();
	page.init();

	jQuery(document).off('signUpResult');
	jQuery(document).on('signUpResult', function (evt) {
		page.signUpResult(evt.detail.code)
	});

	window.signUpResult = function (code) {
		$.event.trigger({
			type: 'signUpResult',
			detail: {
				code: code
			}
		})
	}
};

// Function to provide the translations from i18nSignupFormV2.php
// Check out Warning.phtml to see how to use this
var translate = function(key) {
	if (typeof signupFormV2Translations !== 'undefined' && signupFormV2Translations[key]) {
		return signupFormV2Translations[key];
	} else {
		return false;
	}
};

jQuery(document).ready(initialize);
jQuery(document).on('domChange', initialize);
